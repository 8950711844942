<template>
  <div class="main-box">
    <el-form class="form-inline" :inline="true" v-if="info && info.user_type==1">
      <el-form-item label="">
        <el-select v-model="search.store" clearable placeholder="请选择门店" size="small">
          <el-option
              v-for="item in storeList"
              :key="item.id"
              :label="item.title"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="handleSearch()">搜索</el-button>
      </el-form-item>
    </el-form>
    <div class="mg-bt-10">
      <el-button type="primary" size="small" icon="el-icon-plus" @click="addGroup()">添加收款方式</el-button>
    </div>
    <el-table
        :data="tableData"
        ref="rfTable"
        border
        style="width: 100%"
        size="mini"
        @selection-change="handleSelectionChange">
      <el-table-column
          prop="title"
          label="名称"
          align="left"
          width="250">
      </el-table-column>
      <el-table-column
          prop="shop_name"
          label="门店">
      </el-table-column>
      <el-table-column
          prop="create_time"
          label="创建时间"
          align="center"
          width="160">
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          align="center"
          width="120">
        <template slot-scope="scope">
          <el-button type="text" size="small" style="margin-right: 10px;" @click="editGroup(scope.row)">编辑</el-button>
          <el-popconfirm
              title="确认删除当前收款方式吗？"
              @confirm="delStaff(scope.row.id)"
          >
            <el-button slot="reference" type="text" size="small" style="color: orangered">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        layout="total, prev, pager, next, jumper"
        :page-sizes="[10, 20, 30, 40]"
        :total="search.total">
    </el-pagination>
    <el-dialog
        title="编辑收款方式"
        :visible.sync="dialogVisible"
        :destroy-on-close="true"
        width="30%">
      <pay-type-add :shopList="storeList" :menuInfo="menuInfo" @callFunc="createOk()"></pay-type-add>
    </el-dialog>
  </div>
</template>
<script>

import PayTypeAdd from "@/components/PayTypeAdd";
export default {
  components: {
    PayTypeAdd
  },
  data() {
    return {
      info: false,
      menuInfo: {},
      dialogVisible: false,
      source_domain: this.config.SOURCE_DOMAIN,
      search: {
        current_page: 1,
        total: 0,
        limit: 10,
        keywords: '',
        store: ''
      },
      tableData: [],
      storeList: []
    };
  },
  created() {
    this.getMyInfo()
    this.getList();
  },
  mounted() {
  },
  computed: {},
  methods: {
    addGroup() {
      this.menuInfo = {}
      this.dialogVisible = true
    },
    editGroup(item) {
      this.menuInfo = JSON.parse(JSON.stringify(item))
      this.dialogVisible = true
    },
    createOk() {
      this.dialogVisible = false
      this.getList()
    },
    getMyInfo() {
      this.$api.merchant.myIndex(res => {
        if(res.errcode==0) {
          this.info = res.data
          if(res.data.user_type==1) {
            this.getStoreList()
          }
        }
      })
    },
    getStoreList() {
      this.$api.merchant.storeIndex({page_size: 100}, res => {
        if (res.errcode == 0) {
          this.storeList = res.data.data
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    getList() {
      var that = this
      that.showLoading()
      var param = {
        page: that.search.current_page,
        page_size: that.search.limit,
        keywords: that.search.keywords,
        store: that.search.store,
      }
      this.$api.operate.paytypeIndex(param, function (res) {
        that.hideLoading()
        if (res.errcode == 0) {
          that.tableData = res.data.data
          that.search.current_page = res.data.current_page
          that.search.total = res.data.total
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    delStaff(id) {
      this.$api.operate.paytypeDel({id:id}, res => {
        if(res.errcode==0) {
          this.success('删除成功')
          this.getList()
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    handleSearch() {
      this.search.current_page = 1
      this.getList()
    },
    handleSelectionChange(rows) {
    },
    handleCurrentChange(val) {
      this.search.current_page = val
      this.getList()
    },
    handleSizeChange(val) {
      this.search.current_page = 1
      this.search.limit = val
      this.getList()
    }
  }
};
</script>
<style scoped>
</style>
